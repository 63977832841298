<template>
	<div style="position: relative;">
		<el-form :model="ruleForm" :inline="true">
			<el-form-item label="输入版本号">
				<el-input v-model="version" placeholder="输入版本号"></el-input>
			</el-form-item>
			<!-- <el-form-item>
				<div class="block">
					<span class="demonstration" style="margin-right: 10px">时间</span>
					<el-date-picker v-model="time" type="daterange" align="right" unlink-panels range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
						value-format="yyyy-MM-dd HH:mm:ss" @change="changeTime">
					</el-date-picker>
				</div>
			</el-form-item> -->

			<!-- <el-form-item>
				<el-button @click="getInfo" type="primary">搜索</el-button>
			</el-form-item> -->
			<!-- <span style="">请选择上传平台：</span>
			<div style="display: inline-block;">
				<el-radio-group v-model="radio1">
					<el-radio-button label="华为"></el-radio-button>
					<el-radio-button label="三星"></el-radio-button>
					<el-radio-button label="OPPO"></el-radio-button>
					<el-radio-button label="小米"></el-radio-button>
					<el-radio-button label="vivo"></el-radio-button>
				</el-radio-group>
			</div> -->
			<el-form-item v-if="User == 'true'">
				<el-button type="primary" style="margin-left: 20px" @click="handleUp(0)">上传华为</el-button>
			</el-form-item>
			<el-form-item v-if="User == 'true'">
				<el-button type="primary" style="margin-left: 20px" @click="handleUp(1)">上传小米</el-button>
			</el-form-item>
			<el-form-item v-if="User == 'true'">
				<el-button type="primary" style="margin-left: 20px" @click="handleUp(2)">上传OPPO</el-button>
			</el-form-item>
			<el-form-item v-if="User == 'true'">
				<el-button type="primary" style="margin-left: 20px" @click="handleUp(3)">上传三星</el-button>
			</el-form-item>
			<el-form-item v-if="User == 'true'">
				<el-button type="primary" style="margin-left: 20px" @click="handleUp(4)">上传vivo</el-button>
			</el-form-item>
		</el-form>
		<div style="height: 30px;">
			<!-- 123 -->
			<span v-if="obj.huaweiUrl != ''">华为已上传</span>
			<span v-if="obj.oppoUrl != ''">OPPO已上传</span>
			<span v-if="obj.samsungUrl != ''">三星已上传</span>
			<span v-if="obj.vivoUrl != ''">vivo已上传</span>
			<span v-if="obj.xiaomiUrl != ''">小米已上传</span>
		</div>
		<div>
			<!-- 图片上传组件辅助-->
			<!-- <el-upload class="avatar-uploader" :action="serverUrl" name="img" :headers="header" :show-file-list="false"
				:on-success="uploadSuccess" :on-error="uploadError" :before-upload="beforeUpload">
			</el-upload> -->
			<!--富文本编辑器组件-->
			<el-row v-loading="uillUpdateImg">
				<quill-editor v-model="detailContent" ref="myQuillEditor" :options="editorOption">
				</quill-editor>
			</el-row>
		</div>
		<div style="margin-top: 200px;">
			<el-button type="primary" style="margin-left: 20px" @click="TJ">提交</el-button>
		</div>
		<!-- <el-table max-height="510px" :data="tableData" stripe style="width: 100%;min-height: 510px">
			<el-table-column prop="versionName" width="160px" label="版本名称"></el-table-column>
			<el-table-column prop="id" label="appid" width="120px"></el-table-column>
			<el-table-column label="二维码" width="150px">
				<template slot-scope="scope">
					<el-image style="width: 50px; height: 50px" :src="scope.row.qrCode"
						:preview-src-list="[scope.row.qrCode]"></el-image>
				</template>
			</el-table-column>
			<el-table-column prop="appUrl" label="下载地址"></el-table-column>
			<el-table-column prop="gmtCreate" label="创建时间" width="200px"></el-table-column>
			<el-table-column v-if="User == 'true'" label="操作" width="120">
				<template slot-scope="scope">
					<el-button @click="edit(scope.row)" type="text" style="color: #409eff" size="small">修改</el-button>
					<el-button @click="del(scope.row)" type="text" style="color: red;" size="small">删除</el-button>
				</template>
			</el-table-column>
		</el-table> -->
		<!-- <div class="block" style="display: flex;justify-content: flex-start;margin-top: 20px">

			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="1"
				:page-sizes="[10, 20, 50, 100]" :page-size="ruleForm.size"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div> -->

		<!--  添加APPDialog  -->
		<el-dialog :visible.sync="dialogVisible2" width="900px" center>
			<div style="font-size: 24px;justify-content: center" slot="title">上传APP</div>
			<div slot="footer">
				<el-form :model="addFrom" label-width="100px">
					<el-form-item v-if="false" label="下载链接">
						<el-input v-model="addFrom.appUrl"></el-input>
					</el-form-item>
					<el-form-item v-model="addFrom.appUrl" label="上传">
						<el-upload class="upload-demo" drag :action="xxUrl+'/upload/file'" multiple
							:on-success="upSuccess" :headers="{'token': token}" ref="myUpload">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							<div v-if="false" class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
						</el-upload>
					</el-form-item>
					<!-- <el-form-item label="版本名称">
						<el-input v-model="addFrom.versionName"></el-input>
					</el-form-item> -->
					<div style="display: flex;justify-content: center">
						<el-button type="primary" @click="upApp">确定</el-button>
						<el-button @click="dialogVisible2 = false">取消</el-button>
					</div>
				</el-form>
			</div>


		</el-dialog>

	</div>
</template>

<script>
	// import VueEditor from "@/components/VueEditor";
	import axios from "axios";
	export default {
		name: "AppList1",
		components: {
			// VueEditor
		},
		data() {
			return {
				version:'',
				uillUpdateImg: false, // 根据图片上传状态来确定是否显示loading动画，刚开始是false,不显示
				serverUrl: '', // 这里写你要上传的图片服务器地址
				header: {
					token: sessionStorage.token
				}, // 有的图片服务器要求请求头需要有token之类的参数，写在这里
				detailContent: '', // 富文本内容
				editorOption: {}, // 富文本编辑器配置
				num: null,
				obj: {
					"huaweiUrl": "",
					"oppoUrl": "",
					"samsungUrl": "",
					"updateContent": "",
					"versionNo": '',
					"vivoUrl": "",
					"xiaomiUrl": ""
				},
				radio1: '华为',
				User: '',
				xxUrl: global.posturl.host,
				dialogVisible: false,
				editForm: {
					appUrl: '',
					id: '',
					versionName: ''
				},
				dialogVisible2: false,
				addFrom: {
					versionName: '',
					appUrl: ''
				},
				ruleForm: {
					// "beginTime": "",
					// "endTime": "",
					"page": 1,
					"size": 10,
					versionName: '',
				},
				total: 0,
				tableData: [],
				time: '',
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},

		created() {
			this.User = sessionStorage.getItem('User')
			// this.getInfo()
			// console.log(this.$refs)
		},
		computed: {
			token() {
				return sessionStorage.getItem("token")
			}

		},
		methods: {
			TJ(){
				this.obj.versionNo = this.version
				this.obj.updateContent = this.detailContent
				this.$ajax('addnewapp', 'post', this.obj).then(res => {
					console.log(res)
					if (res.code == 2000) {
						this.$message({
							message: '添加成功',
							type: 'success'
						});
						this.$router.go(-1)
						// this.dialogVisible = false
						// this.getInfo()
					} else {
						this.$message({
							message: res.message,
							type: 'warning'
						});
					}
				})
			},
			// 上传图片前
			beforeUpload(res, file) {},
			// 上传图片成功
			uploadSuccess(res, file) {},
			// 上传图片失败
			uploadError(res, file) {},
			// getInfo() {
			// 	this.$ajax('appPage', 'post', this.ruleForm).then(res => {
			// 		console.log(res)
			// 		this.tableData = res.data.records
			// 		this.total = res.data.total
			// 	})
			// },
			changeTime(time) {
				console.log(time)
				if (time) {
					this.ruleForm.beginTime = time[0]
					this.ruleForm.endTime = time[1]
				} else {
					this.ruleForm.beginTime = ''
					this.ruleForm.endTime = ''
				}
				this.getInfo()
			},
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
				this.ruleForm.size = val
				this.getInfo()
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.ruleForm.page = val
				this.getInfo()
			},
			edit(row) {
				console.log(row)
				this.dialogVisible = true
				this.editForm = row
			},
			// 删除
			del(row) {
				console.log(row)
				this.$confirm('此操作将永久删除该文件是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					axios.delete(global.posturl.host + '/app/delete/' + row.id, {
						headers: {
							'token': sessionStorage.getItem('token')
						},
					}).then(res => {
						console.log(res)
						this.getInfo()
						this.$message({
							type: 'success',
							message: '删除成功'
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			editApp() {
				this.$ajax('appUpdate', 'post', this.editForm).then(res => {
					console.log(res)
					if (res.code == 2000) {
						this.$message({
							message: '修改信息成功',
							type: 'success'
						});
						this.dialogVisible = false
						this.getInfo()
					} else {
						this.$message({
							message: res.message,
							type: 'warning'
						});
					}
				})
			},
			upSuccessED(response, file, fileList) {
				console.log(response, file, fileList)
				this.editForm.appUrl = response.data.link
			},
			// 上传APP成功
			upSuccess(response, file, fileList) {
				console.log(response, file, fileList)
				this.addFrom.appUrl = response.data.link
			},
			// 提交上传
			upApp() {
				if (this.addFrom.appUrl == '') {
					this.$message({
						message: '请仔细检查表单内容',
						type: 'warning'
					});
				} else {
					if (this.num == 0) {
						this.obj.huaweiUrl = this.addFrom.appUrl
						console.log(this.obj);
						this.dialogVisible2 = false
					} else if (this.num == 1) {
						this.obj.xiaomiUrl = this.addFrom.appUrl
						console.log(this.obj);
						this.dialogVisible2 = false
					} else if (this.num == 2) {
						this.obj.oppoUrl = this.addFrom.appUrl
						console.log(this.obj);
						this.dialogVisible2 = false
					} else if (this.num == 3) {
						this.obj.samsungUrl = this.addFrom.appUrl
						console.log(this.obj);
						this.dialogVisible2 = false
					} else if (this.num == 4) {
						this.obj.vivoUrl = this.addFrom.appUrl
						console.log(this.obj);
						this.dialogVisible2 = false
					}
					// this.$ajax('appAdd', 'post', this.addFrom).then(res => {
					// 	console.log(res)
					// 	this.$message({
					// 		message: '上传成功',
					// 		type: 'success'
					// 	});
					// 	this.dialogVisible2 = false
					// 	this.getInfo()
					// })
				}
			},
			handleUp(id) {
				// this.$router.push('UpdatePushApp')
				// console.log(this.radio1,'radio1!!!');
				// return
				this.dialogVisible2 = true
				this.$refs.myUpload.clearFiles()
				this.addFrom.versionName = ''
				this.addFrom.appUrl = ''
				this.num = id
				console.log(this.detailContent, 'detailContent！！！！！');
			},
		}
	}
</script>

<style scoped lang="scss">
	.upload-demo {
		display: flex;
		justify-content: flex-start;
	}
</style>
